<!-- 会员管理 -->
<template>
    <div class="membermanagebox">

        <el-card>
            <div class="global-search-box">
                <div class="search-top-button" v-if="hasPerm(['user.store'])">
                    <el-button type="primary" @click="addmember">新增会员</el-button>
                </div>
                <div class="search-content">
                    <div class="search-item">
                        <el-input placeholder="手机号、昵称、姓名" v-model="search.selectObj.value">
                            <template slot="prepend">
                                <el-select v-model="search.selectObj.option" placeholder="请选择">
                                    <el-option value="binding" label="手机号"></el-option>
                                    <el-option value="username" label="账号"></el-option>
                                    <el-option value="nickname" label="昵称"></el-option>
                                </el-select>
                            </template>
                        </el-input>
                    </div>
                    <div class="search-item">
                        <label class="search-label">会员等级：</label>
                        <el-select v-model="search.level" clearable placeholder="请选择">
                            <el-option v-for="item in common_info['user.level_list']" :key="item.id" :label="item.name"
                                :value="item.level">
                            </el-option>
                        </el-select>
                    </div>
                    <div class="search-item">
                        <label class="search-label">来源渠道：</label>
                        <el-select v-model="search.platform" clearable placeholder="请选择">
                            <el-option v-for="(item, index) in userPlatForm" :key="index" :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </div>
                    <div class="search-item">
                        <label class="search-label">注册时间：</label>
                        <div class="search-time-between">
                            <el-date-picker v-model="search.created_at" type="datetimerange"
                                :picker-options="pickerOptionLater" start-placeholder="开始日期" format="yyyy-MM-dd"
                                end-placeholder="结束日期" :default-time="['00:00:00', '23:59:59']">
                            </el-date-picker>
                        </div>
                    </div>
                    <div class="search-item">
                        <label class="search-label">余额:</label>
                        <div class="number-of-intervals">
                            <el-input type="number" v-model="search.money.balancestart">
                                <template slot="append">元</template>
                            </el-input>
                            <span>-</span>
                            <el-input type="number" v-model="search.money.balanceend">
                                <template slot="append">元</template>
                            </el-input>
                        </div>
                    </div>
                    <div class="search-item">
                        <label class="search-label">余额状态：</label>
                        <el-select v-model="search.is_freeze" clearable placeholder="请选择">
                            <el-option v-for="(item, index) in userIsFreeze" :key="index" :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </div>
                </div>
                <div class="serach-buttom-button">
                    <el-button type="primary" @click="handleSearch">搜索</el-button>
                    <el-button @click="handleClear">重置条件</el-button>
                    <el-button @click="exportTable">导出报表</el-button>
                </div>
            </div>

            <div class="membermanagebut">
                <div class="membernumber">会员数量：<span>{{ total }}</span></div>
                <div class="boxtableconter">
                    <div class="boxtable">
                        <el-table :data="memberData" ref="multipleTable" style="width: 100%"
                            @selection-change="singletable">
                            <el-table-column type="selection" width="55"></el-table-column>
                            <el-table-column label="姓名" min-width="240">
                                <template slot-scope="scope">
                                    <div class="memberuser clearfloat">
                                        <div class="memberuserleft">
                                            <!-- <img :src="scope.row.memberimg" alt=""> -->
                                            <img :src="scope.row.avatar_url" alt="avatar">
                                        </div>
                                        <div class="memberuseright">
                                            <p>{{ scope.row.username }}</p>
                                            <p>ID: {{ scope.row.id }}</p>
                                        </div>
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column label="昵称" prop="nickname" min-width="180"></el-table-column>
                            <el-table-column label="手机号" prop="binding" min-width="180"></el-table-column>
                            <!-- <el-table-column label="会员来源" prop="memberly" width="180"></el-table-column> -->
                            <el-table-column label="会员等级" sortable min-width="200">
                                <template slot-scope="scope">
                                    {{ common_info["user.level_list"] && (common_info["user.level_list"].find(item =>
                                        item.level
                                        == scope.row.level) && common_info["user.level_list"].find(item => item.level ==
                                            scope.row.level).hasOwnProperty("name") ? common_info["user.level_list"].find(item =>
                                                item.level
                                                == scope.row.level).name : "未知等级-等级不匹配") }}
                                </template>
                            </el-table-column>
                            <el-table-column label="订单" min-width="140">
                                <template slot-scope="scope">
                                    <div class="tableaddress">
                                        <a href="javascript:void(0);">{{ scope.row.user_order_count }}</a>
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column label="余额" prop="invoicesend" min-width="140">
                                <template slot-scope="scope">
                                    <div class="tableaddress">
                                        <a href="javascript:void(0);" v-if="hasPerm(['user.update.recharge'])">{{
                                            scope.row.money }} <span @click="handleTopUp(scope.row)">充值</span></a>
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column label="拉黑" min-width="100" style="text-align: center;">
                                <template slot-scope="scope">
                                    <el-switch :active-value="1" :inactive-value="0" v-model="scope.row.blacklist"
                                        @change="handleBlackChange(scope.row)"
                                        :disabled="!hasPerm(['user.update.black'])"></el-switch>
                                </template>
                            </el-table-column>
                            <el-table-column label="冻结" min-width="100" style="text-align: center;">
                                <template slot-scope="scope">
                                    <el-switch :active-value="1" :inactive-value="0" v-model="scope.row.is_freeze"
                                        @change="handleFreezeChange(scope.row)"
                                        :disabled="!hasPerm(['user.update.freeze'])"></el-switch>
                                </template>
                            </el-table-column>
                            <el-table-column label="注册时间" prop="created_at" min-width="220"></el-table-column>
                            <el-table-column label="操作" fixed="right" min-width="200">
                                <template slot-scope="scope">
                                    <div class="setoperate">
                                        <a href="javascript:void(0);" @click="memberevise($event, scope.row)"
                                            v-if="hasPerm(['user.update'])">编辑</a>
                                        <!-- <a href="javascript:void(0);" @click="handleFreeze($event, 'freeze', scope.row)"
                                            v-show="scope.row.is_freeze == 0">冻结</a>
                                        <a href="javascript:void(0);" @click="handleFreeze($event, 'rescind', scope.row)"
                                                                v-show="scope.row.is_freeze == 1">解除</a> -->
                                        <a href="javascript:void(0);" @click="handleModifyPwd(scope.row)"
                                            v-if="hasPerm(['user.update.password'])">修改密码</a>
                                    </div>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                    <div class="orderlastbut clearfloat">
                        <div class="orderlastbutleft clearfloat">
                            <p><span class="ordercheck"><el-checkbox v-model="memberchecked"
                                        @change="allchange">当前页全选</el-checkbox></span></p>
                            <a href="javascript:void(0);" class="active" @click="exportTable">导出报表</a>
                        </div>
                        <div class="orderlastbutright">
                            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                                :current-page="currentPage1" :page-sizes="[10, 20, 30, 50]" :page-size="10"
                                layout="total, sizes, prev, pager, next, jumper" :total="total">
                            </el-pagination>
                        </div>
                    </div>
                </div>
            </div>

        </el-card>

        <!-- 新增/编辑弹层 -->
        <el-dialog :title="sitivetext" class="membertc" :visible.sync="membertype" :show-close="false">
            <div class="membertcbox">
                <el-form ref="form" :model="memberAddAndUpdateInfo" label-width="20%" :rules="rules">
                    <ul>
                        <li>
                            <el-form-item class="clearfloat" label="手机号" prop="binding">
                                <!-- <span><i>*</i> 手机号</span> -->
                                <div class="addressright">
                                    <el-input type="text" v-model="memberAddAndUpdateInfo.binding" placeholder="请输入手机号"
                                        maxlength="11"></el-input>
                                </div>
                            </el-form-item>
                        </li>
                        <template v-if="!revisepass">
                            <li>
                                <el-form-item class="clearfloat" label="登录密码" prop="password">
                                    <!-- <span><i>*</i> 登录密码</span> -->
                                    <div class="addressright">
                                        <el-input type="password" v-model="memberAddAndUpdateInfo.password"
                                            placeholder="请输入6位及以上登录密码"></el-input>
                                    </div>
                                </el-form-item>

                            </li>
                            <li>
                                <el-form-item class="clearfloat" label="确认登录密码" prop="password_ag"
                                    :rules="{ required: true, validator: this.handlePwdValid, trigger: 'blur' }">
                                    <!-- <span><i>*</i> 确认登录密码</span> -->
                                    <div class="addressright">
                                        <el-input type="password" v-model="memberAddAndUpdateInfo.password_ag"
                                            placeholder="请确认登录密码"></el-input>
                                    </div>
                                </el-form-item>
                            </li>
                        </template>
                        <li>
                            <el-form-item class="clearfloat" label="会员名" prop="username">
                                <!-- <span><i>*</i> 会员名</span> -->
                                <div class="addressright">
                                    <el-input type="text" v-model="memberAddAndUpdateInfo.username" placeholder="请输入会员名" />
                                </div>
                            </el-form-item>
                        </li>
                        <li class="clearfloat">
                            <el-form-item class="clearfloat" label="昵称" prop="nickname">
                                <!-- <span><i>*</i> 昵称</span> -->
                                <div class="addressright">
                                    <el-input type="text" v-model="memberAddAndUpdateInfo.nickname" placeholder="请输入昵称" />
                                </div>
                            </el-form-item>

                        </li>
                        <li>
                            <el-form-item class="clearfloat" label="用户头像" prop="avatar_url">
                                <!-- <span><i>*</i> 用户头像</span> -->
                                <div class="addressright">
                                    <div class="addresstx" @click="handleImageClick">
                                        <i class="el-icon-plus" v-show="!memberAddAndUpdateInfo.avatar_url"></i>
                                        <img class="addresstximage" :src="memberAddAndUpdateInfo.avatar_url"
                                            v-show="!!memberAddAndUpdateInfo.avatar_url" />
                                    </div>
                                </div>
                            </el-form-item>
                        </li>
                        <li>
                            <el-form-item class="clearfloat" label="会员等级" prop="level">
                                <!-- <span><i>*</i> 会员等级</span> -->
                                <div class="addressright">
                                    <template>
                                        <el-select v-model="memberAddAndUpdateInfo.level" clearable placeholder="请选择">
                                            <el-option
                                                v-for="                item                 in                 common_info['user.level_list']                "
                                                :key="item.id" :value="item.level" :label="item.name"></el-option>
                                        </el-select>
                                    </template>
                                </div>
                            </el-form-item>
                        </li>
                        <!-- <li>
                            <el-form-item class="clearfloat" label="加入黑名单" prop="blacklist">
                                <div class="addressright addressrighted">
                                    <template>
                                        <el-radio v-model="memberAddAndUpdateInfo.blacklist" :label="0">开启</el-radio>
                                        <el-radio v-model="memberAddAndUpdateInfo.blacklist" :label="1">关闭</el-radio>
                                    </template>
                                    <p>加入黑名单后，用户将无法下单</p>
                                </div>
                            </el-form-item>
                                        </li> -->
                        <li>
                            <el-form-item class="clearfloat" label="备注" prop="comments">
                                <!-- <span>备注</span> -->
                                <div class="addressright">
                                    <el-input type="text" v-model="memberAddAndUpdateInfo.comments"
                                        placeholder="请输入备注"></el-input>
                                </div>
                            </el-form-item>
                        </li>
                    </ul>
                </el-form>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button @click="handleCancel">取 消</el-button>
                <el-button @click="handleSubmit" class="addressqr" v-if="hasPerm(['user.update', 'user.update.level'])">确
                    定</el-button>
            </div>
        </el-dialog>
        <!-- 图片 -->
        <pictureSelect ref="imglist" :multipled="is_multiple" @change="imgbrand($event)"></pictureSelect>
        <!-- 充值余额弹层 -->
        <el-dialog title="调整余额" class="balancetc" :visible.sync="balancetype" width="480px">
            <div class="rbalancebox">
                <el-form :model="balanceObj" ref="balanceForm">
                    <ul>
                        <li class="clearfloat balanceformitem">
                            <el-form-item label="余额" prop="recharge_type"
                                :rules="{ required: true, message: '请选择操作金额的类型!', trigger: 'change' }">
                                <!-- <span class="memberlabel">余额</span> -->
                                <div class="orderfirstleft">
                                    <template>
                                        <el-select v-model="balanceObj.recharge_type" placeholder="请选择">
                                            <el-option
                                                v-for="                item                 in                 rechargeType                "
                                                :value="item.value" :label="item.label" :key="item.value"></el-option>
                                            <!-- <el-option value="2" label="减少"></el-option> -->
                                        </el-select>
                                    </template>
                                </div>
                            </el-form-item>
                            <el-form-item prop="price" :rules="{ required: true, validator: priceValid, trigger: 'blur' }">
                                <div class="orderfirstinput">
                                    <el-input-number type="number" placeholder="请输入金额" :controls="false" :precision="0"
                                        v-model.number="balanceObj.price"></el-input-number>
                                </div>
                            </el-form-item>
                        </li>
                        <!-- 余额类型暂不需要，先隐藏 -->
                        <!-- <li class="clearfloat" v-if="false">
                            <span class="memberlabel">余额类型</span>
                            <div class="deliveryright">
                                <template>
                                    <el-radio v-model="radio" label="1">不可提现</el-radio>
                                    <el-radio v-model="radio" label="2">可提现</el-radio>
                                </template>
                                <p>选择可提现余额时，该笔充值金额会员可在手机端进行提现，请谨慎选择。</p>
                            </div>
                                                                                                </li> -->
                        <li class="clearfloat comments">
                            <el-form-item label="备注" prop="desc"
                                :rules="{ required: true, message: '请输入备注信息!', trigger: 'change' }">
                                <!-- <span class="memberlabel">备注</span> -->
                                <div class="deliveryright">
                                    <el-input type="textarea" v-model="balanceObj.desc" aria-placeholder="请输入备注"></el-input>
                                </div>
                            </el-form-item>
                        </li>
                    </ul>
                </el-form>

            </div>
            <div slot="footer" class="dialog-footer">
                <el-button @click="handleBalanceCancel">取 消</el-button>
                <el-button @click="handleBalanceSubmit" class="addressqr">确 定</el-button>
            </div>
        </el-dialog>
        <!-- 修改密码弹层 -->
        <el-dialog title="修改密码" class="balancetc" :visible.sync="revisetype">
            <div class="rbalancebox">
                <el-form :model="modifyPwdObj" ref="modifyPwdForm">
                    <ul>
                        <li>
                            <el-form-item class="clearfloat" label="新密码" prop="password" label-width="90"
                                :rules="{ required: true, validator: this.modifyPwdValid, trigger: 'blur' }">
                                <!-- <span class="memberlabel">新密码</span> -->
                                <div class="reviseright">
                                    <el-input type="password" placeholder="请输入新密码"
                                        v-model="modifyPwdObj.password"></el-input>
                                </div>
                            </el-form-item>
                        </li>
                    </ul>
                </el-form>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button @click="handleModifyPwdCancel">取 消</el-button>
                <el-button @click="handleModifyPwdSubmit" class="addressqr">确 定</el-button>
            </div>
        </el-dialog>

        <!-- 导出报表 -->
        <diy-export-more ref="diyExportMore" dialogExportKeys="user.export.field.list" dialogExportUrl="/user/export"
            :dialogExportIsSearch="true"></diy-export-more>
    </div>
</template>

<script>
import { Message } from 'element-ui';
import comments from "../../../common/common";
import { mapState } from "vuex";
import common from '../../../common/common';
export default {
    data() {
        return {
            search: { //搜索配置项
                selectObj: {
                    option: "binding", //选择下来key值
                    value: "",//输入的值
                    type: 'selectSearch',
                    op: 'search'
                },
                keyword: "",//用户搜索
                level: '', //会员等级
                platform: '', //来源渠道
                created_at: {
                    createtimestart: '', //注册开始时间
                    createtimeend: '', //注册结束时间
                },
                money: {
                    balancestart: "",//余额范围start
                    balanceend: "",//余额范围end
                },
                balancetyper: '', //余额状态
                //numinpstart: "",//积分start
                //numinpend: "",//积分end
                //memberstate: "",//会员状态
                //canceltype: "",//申请注销
            },
            blacklist: 0,//拉黑用户 0:拉黑，1:解除
            pickerOptionLater: {
                // 所有的截止时间为当天的23.59.59
                shortcuts: [{
                    text: '今天',
                    onClick(picker) {
                        const start = new Date().setHours(0, 0, 0, 0);
                        const end = new Date().setHours(23, 59, 59, 59);
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '7天',
                    onClick(picker) {
                        const end = new Date().setHours(23, 59, 59, 59);
                        const start = new Date();
                        start.setTime(end - (3600 * 1000 * 24 * 7 - 1000));
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '30天',
                    onClick(picker) {
                        const end = new Date().setHours(23, 59, 59, 59);
                        const start = new Date();
                        start.setTime(end - (3600 * 1000 * 24 * 30 - 1000));
                        picker.$emit('pick', [start, end]);
                    }
                }]

            },
            is_filter: false, //是否展开高级搜索
            memberData: [], //会员列表
            currentPage1: 1,
            total: 0,
            memberchecked: false, //是否选中会员列表
            multipleSelection: [], //表格中选中的数据
            balancetype: false, //控制余额是否显示
            valuefirst: '', //增加减少
            radio: '1',
            revisetype: false, //控制修改密码是否显示
            membertype: false, //控制新增/编辑会员是否显示
            sitivetext: '新增会员',
            memberAddAndUpdateInfo: {
                binding: "",//手机号
                password: "",//登录密码
                password_ag: "",//确认密码
                username: "",//会员名
                nickname: "",//昵称
                avatar_url: "",//用户头像
                level: "",//会员等级 0:普通用户
                blacklist: "",//黑名单 0:否 1:是
                comments: "",//备注
            },
            is_multiple: 0,//头像单选多选0:单选，1:多选
            radio1: '2',//是否加入黑名单
            revisepass: false,//编辑时隐藏密码
            pageInfo: {
                page: 1,
                limit: 10
            },
            modifyPwdObj: {
                password: "",//修改密码
            },
            rules: {
                "binding": [{ required: true, validator: this.phoneValid, trigger: 'blur' }],
                "password": [{ required: true, message: "请输入密码!", trigger: 'blur' }, { min: 6, message: "请确保密码最少为6个字符", trigger: 'blur' }],
                "username": [{ required: true, message: "请输入会员名!", trigger: 'blur' }],
                "nickname": [{ required: true, message: "请输入昵称名!", trigger: 'blur' }],
                "avatar_url": [{ required: true, message: "请选择头像!", trigger: 'blur' }],
                "level": [{ required: true, message: "请选择会员等级!", trigger: 'blur' }],
            },
            searchObj: {
                search: [],
                order: [
                    {
                        "key": "created_at",
                        "value": "asc"
                    }
                ]
            },
            balanceObj: {
                recharge_type: "1",//增加还是减少，1：增加 2：减少
                price: "",//输入的余额
                desc: "",//备注
            }
        }
    },
    beforeCreate() {
        // 清空commonInfo缓存
        this.$store.commit("commonInfo", "");
    },
    created() {
        //请求全局配置资源(user.level_list,user.is_freeze)
        comments.getCommonInfo(this, { keys: Array.of("user.level_list", "user.is_freeze", "user.platform", "user.recharge_type") });
        this.getMemberUserList({ ...this.searchObj, ...this.pageInfo });
    },
    methods: {
        // price valid
        priceValid(rules, value, callback) {
            if (value == "" || value == 0) {
                callback(new Error("请输入余额!"));
            }
            if (value.toString().includes(".")) {
                callback(new Error("余额不可为小数!"))
            }
            callback();
        },
        filtertab() {
            this.is_filter = !this.is_filter
        },
        // 选择所有会员
        allchange(value) {
            if (!value) {
                this.$refs.multipleTable.clearSelection();
            } else {
                this.$refs.multipleTable.toggleAllSelection();
            }
            this.memberchecked = value;
        },
        // 单个表格被选中
        singletable(val) {
            this.multipleSelection = val;
            if (val.length == this.memberData.length) {
                this.memberchecked = true;
            } else {
                this.memberchecked = false;
            }
        },
        // pageSize
        handleSizeChange(limit) {
            this.$set(this, "pageInfo", { ...this.pageInfo, limit });
            this.getMemberUserList({ ...this.searchObj, ...this.pageInfo });
        },
        // pageNum
        handleCurrentChange(page) {
            this.$set(this, "pageInfo", { ...this.pageInfo, page });
            this.getMemberUserList({ ...this.searchObj, ...this.pageInfo });
        },
        //新增会员
        addmember() {
            this.membertype = true;
            this.revisepass = false,
                this.sitivetext = '新增会员'
        },
        // 编辑会员
        memberevise(event, row) {
            this.membertype = true;
            this.revisepass = true,
                this.sitivetext = '编辑会员'
            this.$nextTick(() => {
                this.$set(this, "memberAddAndUpdateInfo", { ...row })
            })
        },

        //上传头像事件
        handleImageClick() {
            this.$refs.imglist.photoVisible = true;
        },
        // 选择头像事件
        imgbrand(val) {
            this.memberAddAndUpdateInfo.avatar_url = val[0].file_url;
            this.$forceUpdate();
        },
        // 提取搜索数据
        extractSearch() {
            return { ...this.searchObj, ...this.pageInfo }
        },
        // 打开导出报表弹窗
        exportTable() {
            this.$refs.diyExportMore.exportMore = true
        },
        //会员用户列表
        async getMemberUserList(options) {
            const result = await this.$get(this.$apis.memberUsers, options);
            if (result.code == 200) {
                this.total = result.data.total
                this.$set(this, "memberData", result.data.list)
                // this.$message({
                //     type: "success",
                //     message: result.message,
                //     offset: 200,
                //     onClose: () => {
                //         this.total = result.data.total
                //         this.$set(this, "memberData", result.data.list)
                //     }
                // })
            } else {
                this.$message({
                    type: "error",
                    message: result.message,
                    offset: 200,
                })
            }

        },

        // 确认密码验证valid
        handlePwdValid(rule, value, callback) {
            console.log(this.memberAddAndUpdateInfo.password + "===", value + "====");
            if (value === '') {
                callback(new Error('请再次输入密码'));
            } else if (value !== this.memberAddAndUpdateInfo.password) {
                callback(new Error('两次输入密码不一致!'));
            } else {
                callback();
            }
        },
        // 手机号Valid
        phoneValid(rule, value, callback) {
            const phoneReg = /^(?:(?:\+|00)86)?1(?:(?:3[\d])|(?:4[5-79])|(?:5[0-35-9])|(?:6[5-7])|(?:7[0-8])|(?:8[\d])|(?:9[1589]))\d{8}$/;
            if (value == "") {
                callback(new Error("请输入手机号!"));
            }
            if (!phoneReg.test(value)) {
                callback(new Error("请按照正确格式输入手机号!"))
            }
            callback();
        },
        // 修改密码valid (不知是否要校验非法字符)
        modifyPwdValid(rule, value, callback) {
            const pwdReg = /[0-9a-zA-Z!@#$%^&*`~()-+=]{6,}/
            if (value == "") {
                callback(new Error("请输入新密码!"));
            }
            if (value == this.memberAddAndUpdateInfo.password) {
                callback(new Error("不能设置新密码与原始密码一样!")); //此校验只能是创建完成后立马修改密码，其他情况需要后端查询密码进行判断
            }
            if (value.length < 6) {
                callback(new Error("密码长度不可小于6个字符!"));
            }
            callback();
        },

        // 确认按钮
        handleSubmit() {
            const newMemberObj = JSON.parse(JSON.stringify(this.memberAddAndUpdateInfo));
            delete newMemberObj["password_ag"]
            this.$refs["form"].validate(valid => {
                if (valid) {
                    if (!this.memberAddAndUpdateInfo.hasOwnProperty("id")) {
                        // 添加用户
                        this.$post(this.$apis.memberUsers, newMemberObj).then(res => {
                            Message[(() => res.code == 200 ? "success" : "error")()]({ message: `${res.code == 200 ? '新增会员成功!' : res.message}`, offset: 200 });
                            res.code == 200 && this.handleCancel();
                            // 刷新table（使用异步操作，否则数据不会更新）
                            res.code == 200 && setTimeout(() => { this.getMemberUserList({ ...this.searchObj, ...this.pageInfo }) }, 0)
                            // this.$set(this, "membertype", res.code != 200);
                        });
                    } else {
                        // 编辑用户
                        this.$put(this.$apis.memberUsers + "/" + this.memberAddAndUpdateInfo.id, this.memberAddAndUpdateInfo).then(res => {
                            Message[(() => res.code == 200 ? "success" : "error")()]({ message: `${res.code == 200 ? '编辑会员成功!' : res.message}`, offset: 200 });
                            res.code == 200 && this.handleCancel();
                            // 刷新table（使用异步操作，否则数据不会更新）
                            res.code == 200 && setTimeout(() => { this.getMemberUserList({ ...this.searchObj, ...this.pageInfo }) }, 0)
                        })
                    }
                }
            })

        },

        // 取消按钮
        handleCancel() {
            this.clearDialog();
            this.$set(this, "membertype", false);
        },

        // 清除dialog
        clearDialog() {
            this.$refs['form'].resetFields();
        },

        // 搜索按钮
        handleSearch() {
            // this.$set(this, "searchObj", { ...this.searchObj, ...this.pageInfo })
            // const entriesResult = Object.entries(this.search);
            // const filterResult = entriesResult.filter(item => item[1].length != 0);
            // // 需要判断哪些搜索项要传递为数组格式，并将其过滤出来
            // /**
            //  * 已下搜索字段需传递为数组
            //  *  balancestart
            //     balanceend
            //     numinpstart
            //     numinpend
            //     createtimestart
            //     createtimeend
            //  */
            // // 筛选出op为between的数据
            // const betArrInfo = filterResult.reduce((pre, item, index, arr) => {
            //     console.log((item[1] && (typeof item[1] != null || typeof item[1] != undefined) && typeof item[1] == "object"), Object.values(item[1]).every(item => (item != "" && item != null)));
            //     if ((item[1] && (typeof item[1] != null || typeof item[1] != undefined) && typeof item[1] == "object") && Object.values(item[1]).every(item => (item != "" && item != null))) {
            //         pre.push(item)
            //     }
            //     return pre;
            // }, [])

            // // 整合op为between的数据
            // const betweenArr = betArrInfo.reduce((pre, item, index, arr) => {
            //     const valueArr = Object.values(item[1]);
            //     const obj = {}
            //     obj['value'] = valueArr;
            //     obj['key'] = item[0];
            //     obj['op'] = "between";
            //     pre.push(obj)
            //     return pre;
            // }, [])
            // // 将结果赋值 this.searchObj.search
            // // 筛选出op为search的数据
            // const seaArrInfo = filterResult.reduce((pre, item, index, arr) => {
            //     if (item[1] && (typeof item[1] != null || typeof item[1] != undefined) && typeof item[1] != "object") {
            //         pre.push(item)
            //     }
            //     return pre;
            // }, [])
            // // 整合op为search的数据
            // const searchArr = seaArrInfo.reduce((pre, item, index, arr) => {
            //     const obj = {}
            //     obj['value'] = item[1];
            //     obj['key'] = (item[0] != 'keyword') ? item[0] : "keyword";
            //     obj['op'] = (item[0] != 'keyword') ? "equal" : "search";
            //     pre.push(obj)
            //     return pre;
            // }, [])
            // // 将结果赋值 this.searchObj.search
            // this.$set(this.searchObj, "search", Array.of().concat(betweenArr).concat(searchArr));
            this.$set(this, "searchObj", { ...this.searchObj, ...this.pageInfo });
            const result = common.handleSearch(this.search);
			this.pageInfo.page = 1;
			this.currentPage1 = 1;
            this.$set(this.searchObj, "search", result);
            // 调用接口刷新table
            this.getMemberUserList({ ...this.searchObj, ...this.pageInfo });
        },

        // 冻结/解绑 -- 隐藏未使用
        handleFreeze(event, type, row) {
            switch (type) {
                case "freeze":
                    //冻结
                    this.$confirm(`确定要将账号【${row.username}】冻结吗？`, '警告', {
                        type: "warning",
                    }).then(() => {
                        this.$put(this.$apis.memberUserFreezeAndrescind + "/" + row.id, { is_freeze: 1 }).then(res => {
                            if (res.code == 200) {
                                Message.success({ message: "冻结成功!", offset: 200 });
                                //刷新table
                                this.getMemberUserList({ ...this.searchObj, ...this.pageInfo });
                            } else {
                                Message.error({ message: res.message, offset: 200 });
                            }
                        }) //TODO: 目前status为静态数据1
                    }).catch(() => {
                        Message.info({ message: "已取消冻结", offset: 200 })
                    })
                    break;
                case "rescind":
                    //解绑
                    this.$confirm(`确定要将账号【${row.username}】解除冻结吗？`, '警告', {
                        type: "warning",
                    }).then(() => {
                        this.$put(this.$apis.memberUserFreezeAndrescind + "/" + row.id, { is_freeze: 0 }).then(res => {
                            if (res.code == 200) {
                                Message.success({ message: "解除冻结成功!", offset: 200 });
                                //刷新table
                                this.getMemberUserList({ ...this.searchObj, ...this.pageInfo });
                            } else {
                                Message.error({ message: res.message, offset: 200 });
                            }
                        }) //TODO: 目前status为静态数据0
                    }).catch(() => {
                        Message.info({ message: "已取消解除冻结", offset: 200 })
                    })
                    break;
            }
        },

        // 拉黑/解除事件
        handleBlackChange({ blacklist, id, nickname }) {
            this.$confirm(`是否将会员昵称【${nickname}】${!blacklist ? '解除黑名单' : '拉入黑名单'}?`, "提示", {
                type: "warning"
            }).then(() => {
                this.$put(`${this.$apis.memberUserBlackList}/${id}`, { blacklist }).then(res => {
                    Message[(() => res.code == 200 ? 'success' : 'error')()](`${res.code == 200 ? `${!blacklist ? '解除' : '拉黑'}会员用户成功!` : res.message}`);
                    // 异步刷新table
                    setTimeout(() => { this.getMemberUserList({ ...this.searchObj, ...this.pageInfo }) }, 0)
                })
            }).catch(err => {
                Message[(() => err != 'cancel' ? 'error' : 'info')()]({
                    message: `${err != 'cancel' ? err : `已取消${!blacklist ? '解除' : '拉黑'}!`}`
                })
                // 异步刷新table
                setTimeout(() => { this.getMemberUserList({ ...this.searchObj, ...this.pageInfo }) }, 0)
            })
        },
        // switch 冻结解除
        handleFreezeChange({ is_freeze, id, nickname }) {
            this.$confirm(`是否将会员昵称【${nickname}】${!is_freeze ? '余额解除冻结' : '余额冻结'}?`, "提示", {
                type: "warning"
            }).then(() => {
                this.$put(`${this.$apis.memberUserFreezeAndrescind}/${id}`, { is_freeze }).then(res => {
                    Message[(() => res.code == 200 ? 'success' : 'error')()](`${res.code == 200 ? `${!is_freeze ? '解除' : ''}余额冻结成功!` : res.message}`);
                    // 异步刷新table
                    setTimeout(() => { this.getMemberUserList({ ...this.searchObj, ...this.pageInfo }) }, 0)
                })
            }).catch(err => {
                Message[(() => err != 'cancel' ? 'error' : 'info')()]({
                    message: `${err != 'cancel' ? err : `已取消${!is_freeze ? '解除' : '冻结'}!`}`
                })
                // 异步刷新table
                setTimeout(() => { this.getMemberUserList({ ...this.searchObj, ...this.pageInfo }) }, 0)
            })
        },

        //修改密码按钮
        handleModifyPwd(row) {
            this.handleModifyPwdSubmit['id'] = row.id; //将id赋值到方法对象的属性id上便于获取
            this.revisetype = true;
        },

        // 修改密码dialog取消按钮
        handleModifyPwdCancel() {
            // 清除字段数据
            this.$refs['modifyPwdForm']['resetFields']();
            this.revisetype = false;
        },

        // 修改密码dialog确定按钮
        handleModifyPwdSubmit() {
            this.$refs['modifyPwdForm']['validate']((valid) => {
                if (valid) {
                    //修改密码
                    this.$put(`${this.$apis.memberModifyPwd}/${this.handleModifyPwdSubmit.id}`, this.modifyPwdObj).then(res => {
                        if (res.code == 200) {
                            Message.success({ message: "修改密码成功!", offset: 200 });
                            this.handleModifyPwdCancel();
                        } else {
                            Message.error({ message: res.message, offset: 200 });
                        }
                    })
                }
            })
        },

        // 充值按钮
        handleTopUp(row) {
            this.handleBalanceSubmit['id'] = row.id;
            this.balancetype = true;
        },
        // 充值取消
        handleBalanceCancel() {
            this.$refs['balanceForm']['resetFields']();
            this.balancetype = false;
        },
        // 充值确定
        handleBalanceSubmit() {
            this.$refs['balanceForm']['validate'](valid => {
                if (valid) {
                    this.$post(`${this.$apis.memberBalance}`, { ...this.balanceObj, user_id: this.handleBalanceSubmit.id }).then(res => {
                        if (res.code == 200) {
                            Message.success({ message: `${this.balanceObj.recharge_type == "1" ? "充值" : "扣款"}成功!`, offset: 200 });
                            this.handleBalanceCancel();
                            // 刷新table
                            this.getMemberUserList({ ...this.searchObj, ...this.pageInfo });
                        } else {
                            Message.error({ message: res.message, offset: 200 });
                        }
                    })
                }
            })
        },
        // 重置条件
        handleClear() {
            this.$set(this, 'search', common.handleClear(this.search));
            // for (const key in this.search) {
            //     // 判断
            //     if (typeof this.search[key] == "object") {
            //         const result = Object.entries(this.search[key]).reduce((pre, item) => {
            //             item[1] = "";
            //             pre.push(item);
            //             return pre;
            //         }, [])
            //         const r = Object.fromEntries(result);
            //         this.search[key] = r;
            //     } else {
            //         this.search[key] = '';
            //     }
            // }
            // 利用异步写法，等循环结束后执行
            setTimeout(() => {
                this.handleSearch();
            }, 0);
        }
    },
    computed: {
        common_info: {
            get() {
                return this.$store.state.common_info
            },
            set() { }
        },
        // 余额状态select计算属性
        userIsFreeze() {
            return common.mapSource2List(this.common_info['user.is_freeze'] || {});
        },
        // 来源渠道
        userPlatForm() {
            return common.mapSource2List(this.common_info['user.platform'] || {});
        },
        // 余额操作属性
        rechargeType() {
            return common.mapSource2List(this.common_info['user.recharge_type'] || {})
        },
    }
}
</script>

<style scoped lang="less">
@import url("css/member.css");
.orderfirstleft .el-select{width: 100%;}
.orderfirstleft /deep/ .el-select .el-input{width: 100%;}
.orderfirstleft /deep/ .el-select .el-input .el-input__inner{border-radius: 4px 0 0 4px;}
.boxtableconter{padding: 0;}
.boxtable /deep/ .el-table th:last-child.el-table__cell{text-align: left;}
.setoperate{text-align: left;}
.addressright .el-select{width: 100%;}
.addressright .el-input{width: 100%;}
.reviseright{width: 100%;}
.reviseright .el-input{width: 100%;}
.addresstx{display: table;}
.boxtable {
    /deep/.el-table .el-table__cell {
        border-bottom: 1px solid #EBEEF5;
    }
}

.membermanagebut {
    padding-top: 20px;
    margin-top: 20px;
    border-top: 1px solid #efefef;
}

.addressright {
    width: 100%;
}

.addresstximage {
    display: block;
    width: 80px;
    height: 80px;
    text-align: center;
    line-height: 80px;
    border: 1px dashed #ededed;
    box-sizing: border-box;
    font-size: 40px;
    color: #dad9d9;
    font-weight: 400;
    cursor: pointer;
    object-fit: cover;
}

.balanceformitem {
    display: flex;
    align-items: center;

    & /deep/.el-form-item {
        display: flex;
        border: none;

        & .orderfirstinput {
            display: flex;
            align-items: center;

            & .el-input-number {
                width: 100%;
            }
        }
    }
}

.comments {
    // margin-left: 9px !important;
}

.orderfirst {
    width: auto !important;
}

.dialog-footer .addressqr:hover {
    color: #fff !important;
}
</style>